<template>
  <div class="w-11/12 mb-4 bg-white rounded-xl shadow-lg text-gray-600">
      <div class="px-6 py-2 rounded-lg flex justify-between items-center cursor-pointer p-3" @click="toggleAccordion" style="border-bottom: gray solid 1px">
        <p class="text-dark text-left"><b>Anexos de la queja</b></p>
        <span class="h-5 w-5 m-2 flex justify-center items-center transition" :class="{'transform rotate-180': accordionOpen}">
          <img src="@/assets/icons/arrow-down.svg" alt="">
        </span>
      </div>
      <div v-if="accordionOpen" class="p-3">
         
        <div class="flex flex-col gap-2">
        <label class="text-xs md:text-xs text-gray-600 text-opacity-80 mt-5 text-left"><b>Anexos</b></label>
            <div class="w-11/12 grid grid-cols-1 gap-2">
            <BaseDownload v-for="(option, index) in complaint.mis_archivos" label="Anexos" :value="option.name_file" :key="index" :url_download="option.url_file"/>
            </div>
        </div>
      </div>
    </div>
</template>

<script>
export default {
  props: ['complaint'],
  data(){
      return {
        accordionOpen: false
      }
  },
  methods: {
      toggleAccordion(){
        this.accordionOpen = !this.accordionOpen; 
      }
  }
}
</script>