<template>
  <div>
      <ComplaintDetail></ComplaintDetail>
  </div>
</template>

<script>
import ComplaintDetail from '@/components/Dashboard/ProductivityReport/ComplaintDetail'
export default {
  components: { ComplaintDetail },

}
</script>