<template>
  <div class="w-full flex flex-row">
    <div v-if="loading" class="flex justify-center items-center">
      <a-spin>
        <a-icon slot="indicator" type="loading" style="font-size: 36px" spin />
      </a-spin>
    </div>

    <div
      v-if="complaint"
      class="w-11/12 max-w-screen-xl flex flex-col lg:flex-col lg:items-start"
    >
      <ComplaintDetailGeneral :complaint="complaint" :customer="customer" />
      <ComplaintDetailAnswer :complaint="complaint" />
      <ComplaintDetailAnnexes :complaint="complaint" />
      <ComplaintDetailCriterion :complaint="complaint" :criterion="criterion" />
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { format } from "date-fns";
import ComplaintDetailGeneral from "@/components/Dashboard/ProductivityReport/ComplaintDetailGeneral";
import ComplaintDetailAnswer from "@/components/Dashboard/ProductivityReport/ComplaintDetailAnswer";
import ComplaintDetailCriterion from "@/components/Dashboard/ProductivityReport/ComplaintDetailCriterion";
import ComplaintDetailAnnexes from "@/components/Dashboard/ProductivityReport/ComplaintDetailAnnexes";

export default {
  components: {
    ComplaintDetailGeneral,
    ComplaintDetailAnswer,
    ComplaintDetailCriterion,
    ComplaintDetailAnnexes,
  },
  async created() {
    const { complaint_id, criterion_id } = this.$route.params;

    this.loading = true;
    let { error, data } = await this.$api.getDetailComplaint(complaint_id);
    this.loading = false;

    if (error) this.showToast("error", "Error recuperando datos de la queja.");

    if (data) {
      const complaint = data;
      const numero_id_CF = complaint.numero_id_CF;
      const tipo_id_CF = complaint.tipo_id_CF;
      this.complaint = {
        ...complaint,
        lgbtiq: complaint.lgbtiq == 1 ? "Sí" : complaint.lgbtiq == 2 ? "No": "",
        specialCondition:
            complaint.condicion_especial === "No aplica"?
              2: complaint.condicion_especial == null ? 
              null:
              1,
        es_apoderado: complaint.apoderado_nombre ? 2 : 1,
        abroad: complaint.departamento_cod && complaint.municipio_cod ? 2 : 1,
        documentacion_final: complaint.documentacion_rta_final ? "Sí" : "No",
        expres: complaint.queja_expres === 2 ? "Sí" : "No",
        tutela_nombre:
          complaint.tutela === 1
            ? "La queja o reclamo tiene asociada una acción de tutela"
            : "La queja o reclamo tiene NO asociada una acción de tutela",
        foreign: complaint.departamento_cod === null ? true : false,
        fecha_cierre: complaint.fecha_cierre
          ? format(new Date(complaint.fecha_cierre), "dd/MM/yyyy hh:mm a")
          : "",
        archivos_apoderado: complaint.mis_archivos.filter(
          (item) => item.reference === "attorney"
        ),
        mis_archivos: complaint.mis_archivos.filter(
          (item) => item.reference !== "attorney"
        ),
      };

      this.criterion = this.complaint?.evaluations.find(
        (el) => el.complaints_criterion === parseInt(criterion_id, 10)
      );
      // Datos del usuario
      this.loading = true;
      let identificationType_id = 1; // Por defecto 1
      const objIdentificationType = this.$store.state.form.optionsIdentificationType?.filter(
        identificationType => identificationType.name == tipo_id_CF
        );
        if (objIdentificationType.length > 0) {
          identificationType_id = objIdentificationType[0].id;
        }
      let result = await this.$api.getQueryPerson(
        `?identification_number=${numero_id_CF}&identification_type=${identificationType_id}`
      );
      this.loading = false;

      if (result.error)
        this.showToast(
          "error",
          "No se encontraron datos del usuario de la queja."
        );

      if (result.data) {
        if (result.data.results.length === 0) {
          // this.showToast(
          //   "error",
          //   "No se encontraron datos del usuario de la queja."
          // );
          // return;
          let { nombres_usuario, numero_id_CF, tipo_id_CF } = this.complaint;
          this.customer = {
            identification_type_name: tipo_id_CF,
            identification_number: numero_id_CF,
            person_type: tipo_persona === "Natural" ? 1 : 2,
            first_name: nombres_usuario.nombres,
            last_name: nombres_usuario.apellidos,
          };
        } else {
          const user = result.data.results[0];

          this.customer = {
            identification_type: user.identification_type,
            identification_number: user.identification_number,
            birth_date: user.birth_date,
            person_type: user.person_type,
            first_name: user.user_id.first_name,
            last_name: user.user_id.last_name,
            phone: user.user_id.phone,
            email: user.user_id.email,
            identification_type_name: complaint.tipo_id_CF,
          };
        }
      }
    }
  },
  data() {
    return {
      customer: null,
      complaint: null,
      criterion: null,
      loading: false,
    };
  },
  computed: {
    ...mapState({
      formOptions: (state) => {
        return state.form;
      },
    }),
  },
  methods: {
    showToast(result = "info", msg) {
      this.$notification[result]({
        message: "Resultado",
        description: msg,
        onClick: () => {},
      });
    },
  },
};
</script>